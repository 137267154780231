<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-btn-group outline v-if="!disabled" class="ColumInnerBtnGroup">
        <q-btn
          v-if="!disabled"
          icon="add"
          color="blue-6"
          text-color="white"
          class="ColumInnerBtn"
          align="center"
          @click.prevent="innerBtnClicked('1')">
          <q-tooltip>
            개선요청등록
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="!disabled"
          icon="add"
          color="orange"
          text-color="white"
          class="ColumInnerBtn"
          align="center"
          @click.prevent="innerBtnClicked('2')">
          <q-tooltip>
            즉시조치등록
          </q-tooltip>
        </q-btn>
      </q-btn-group>
      <br>
      <q-table
        grid
        ref
        title=""
        :data="data.mobileImproveModelList"
        hide-header
        hide-bottom
        :rows-per-page-options="[0]"
        virtual-scroll
      >
        <template v-slot:item="props">
          <div class="col-12">
            <!-- <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)"> -->
            <q-card class="mobileTableCardLayer" @click="getDetailPage(props.row)">
              <q-card-section horizontal class="bg-grey-2">
                <q-card-section class="q-pt-xs">
                  <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">{{ props.row.ibmTitle }}</div>
                </q-card-section>
              </q-card-section>
              <q-card-section class="grid-card-etc">
                <div class="text-grid-etc-title">
                  요청부서 :
                </div>
                <div class="text-grid-etc">
                  {{ props.row.improveRequestDeptName }}
                  <span>
                    <q-badge v-if="props.row.completeDateOver > 0" color="negative" label="지연" />
                  </span>
                </div>
                <!-- <div class="text-grid-etc-title">
                  조치부서 :
                </div>
                <div class="text-grid-etc">
                  {{ props.row.actionDeptName }}
                </div> -->
                <br>
                <div class="text-grid-etc-title">
                  완료 요청일 : 
                </div>
                <div class="text-grid-etc">
                  {{ props.row.actionCompleteRequestDate }}
                </div>
                <div :class="['text-grid-etc', getColorStatus(props.row.ibmStepCd)]">
                  {{ props.row.ibmStepName }}
                </div>
              </q-card-section>
            </q-card>
          </div>
        </template>
      </q-table>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.mobileImproveModelList.length === 0">
        <q-card class="mobileTableCardLayer">
          <q-card-section horizontal class="bg-grey-2 text-center">
            <q-card-section class="q-pt-xs">
              <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">등록된 개선관리가 없습니다.</div>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'equipment-daily-impr',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        checkStatusCd: '',
        dailyName: '',
        utlityFlag: '',
        equipmentName:'',
        mobileImproveModelList: [], 
      }),
    },
    equipmentName: {
      type: String,
      default: '',
    },
    equipmentCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            align: 'left',
            style: 'width:80px',
            sortable: false,
            colClass: 6,
          },
          {
            required: true,
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:20%',
            sortable: false,
            colClass: 6,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:20%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '200px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(btn) {
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: this.equipmentName,
          relationTableKey: this.equipmentCd+"/"+this.data.dailyCheckId,
          ibmTaskTypeCd: 'ITT0000070',
          ibmTaskUnderTypeCd: 'ITTU000120'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: this.equipmentName,
          relationTableKey: this.equipmentCd+"/"+this.data.dailyCheckId,
          ibmTaskTypeCd: 'ITT0000070',
          ibmTaskUnderTypeCd: 'ITTU000120'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.$emit('getDetail');
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'IS00000001': // 요청중
          cls = 'text-primary';
          break;
        case 'IS00000005': // 조치부서 접수중
          cls = 'text-positive';
          break;
        case 'IS00000010': // 조치부서 조치중
          cls = 'text-orange-10';
          break;
        case 'IS00000015': // 요청부서 검토중
          cls = 'text-red';
          break;
      }
      return cls;
    },
    getDetailPage(key) {
      this.popupOptions.title = '개선 상세'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: key.sopImprovementId,
        ibmTaskTypeCd: key.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail');
    },
  }
};
</script>