var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
    [
      !_vm.disabled
        ? _c(
            "q-btn-group",
            { staticClass: "ColumInnerBtnGroup", attrs: { outline: "" } },
            [
              !_vm.disabled
                ? _c(
                    "q-btn",
                    {
                      staticClass: "ColumInnerBtn",
                      attrs: {
                        icon: "add",
                        color: "blue-6",
                        "text-color": "white",
                        align: "center",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.innerBtnClicked("1")
                        },
                      },
                    },
                    [_c("q-tooltip", [_vm._v(" 개선요청등록 ")])],
                    1
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "q-btn",
                    {
                      staticClass: "ColumInnerBtn",
                      attrs: {
                        icon: "add",
                        color: "orange",
                        "text-color": "white",
                        align: "center",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.innerBtnClicked("2")
                        },
                      },
                    },
                    [_c("q-tooltip", [_vm._v(" 즉시조치등록 ")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("q-table", {
        ref: "",
        attrs: {
          grid: "",
          title: "",
          data: _vm.data.mobileImproveModelList,
          "hide-header": "",
          "hide-bottom": "",
          "rows-per-page-options": [0],
          "virtual-scroll": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "q-card",
                      {
                        staticClass: "mobileTableCardLayer",
                        on: {
                          click: function ($event) {
                            return _vm.getDetailPage(props.row)
                          },
                        },
                      },
                      [
                        _c(
                          "q-card-section",
                          {
                            staticClass: "bg-grey-2",
                            attrs: { horizontal: "" },
                          },
                          [
                            _c("q-card-section", { staticClass: "q-pt-xs" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                                },
                                [_vm._v(_vm._s(props.row.ibmTitle))]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("q-card-section", { staticClass: "grid-card-etc" }, [
                          _c("div", { staticClass: "text-grid-etc-title" }, [
                            _vm._v(" 요청부서 : "),
                          ]),
                          _c("div", { staticClass: "text-grid-etc" }, [
                            _vm._v(
                              " " +
                                _vm._s(props.row.improveRequestDeptName) +
                                " "
                            ),
                            _c(
                              "span",
                              [
                                props.row.completeDateOver > 0
                                  ? _c("q-badge", {
                                      attrs: {
                                        color: "negative",
                                        label: "지연",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("br"),
                          _c("div", { staticClass: "text-grid-etc-title" }, [
                            _vm._v(" 완료 요청일 : "),
                          ]),
                          _c("div", { staticClass: "text-grid-etc" }, [
                            _vm._v(
                              " " +
                                _vm._s(props.row.actionCompleteRequestDate) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              class: [
                                "text-grid-etc",
                                _vm.getColorStatus(props.row.ibmStepCd),
                              ],
                            },
                            [_vm._v(" " + _vm._s(props.row.ibmStepName) + " ")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.data.mobileImproveModelList.length === 0
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" },
            [
              _c(
                "q-card",
                { staticClass: "mobileTableCardLayer" },
                [
                  _c(
                    "q-card-section",
                    {
                      staticClass: "bg-grey-2 text-center",
                      attrs: { horizontal: "" },
                    },
                    [
                      _c("q-card-section", { staticClass: "q-pt-xs" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                          },
                          [_vm._v("등록된 개선관리가 없습니다.")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }